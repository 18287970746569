import httpRequest from '../request'

// 删除服务站
export function deleteServiceStation<T>(station_ids) {
  return httpRequest.delete<T>(`seeyard/station/${station_ids}`)
}
//编辑服务站
export function setServiceStation<T>(station_id, payload) {
  return httpRequest.put<T>(`seeyard/station/${station_id}`, payload)
}
// 添加服务站
export function addService<T>(payload) {
  return httpRequest.post<T>('seeyard/station', payload)
}
// 获取服务站
export function getService<T>(station_id) {
  return httpRequest.get<T>(`seeyard/station/${station_id}`)
}
// 获取统计用户登录频率信息
export function getEchartsData<T>(payload) {
  return httpRequest.post<T>('seeyard/login/statistics', payload)
}
// 获取-平台概况统计
export function getSurveyOverview<T>() {
  return httpRequest.get<T>('seeyard/user/statistics')
}
// 获取全部服务站
export function getAllService<T>(params) {
  return httpRequest.get<T>(`seeyard/station`, params)
}
