export const userLoginList = [
  {
    name: '月活跃率',
    label: 'user_active_volume',
    color: '#00A870',
  },
  {
    name: '下单用户月活跃率',
    label: 'order_active_volume',
    color: '#6476FF',
  },
]
export const surveyList = [
  {
    label: 'user_count',
    type: '用户数',
    note: '四合院所有用户总数',
    count: 0,
    unit: '人',
  },
  {
    label: 'customer_count',
    type: '客户数',
    note: '在四合院下过订单的用户数',
    count: 0,
    unit: '人',
  },
  {
    label: 'total_order_account',
    type: '累计下单数',
    note: '累计下单数',
    count: 0,
    unit: '单',
  },
  {
    label: 'finish_order_count',
    type: '累计完成订单数',
    note: '累计完成订单数',
    count: 0,
    unit: '单',
  },
]
export const orderTypeColumns = [
  {
    title: '下单类型',
    dataIndex: 'type',
  },
  {
    title: '数量（单）',
    dataIndex: 'count',
  },
  {
    title: '数量占比（%）',
    dataIndex: 'rate',
  },
]
